// Theme Colors

$body-color: #323232;  // Matches Esri's primary default theme color

 // Optional variable overrides here
 $custom-theme-colors: (
   "primary": $body-color
 );

$primary: $body-color; // set the $primary variable
