@import './CAVariables.scss';

html,
body,
.mapContainer,
#root {
  padding: 0;
  margin: 0;
  height: 100vh !important;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.esri-ui {
  bottom: env(safe-area-inset-bottom) !important;
}

.esri-popup__action-text {
  display: none;
}

#dijit_form_Form_0 > div.dijitDialogPaneContentArea > table > tbody > tr:nth-child(1) > td > label,
#dijit_form_Form_0 > div.dijitDialogPaneContentArea > table > tbody > tr:nth-child(2) > td > label {
  width: 100% !important;
}

// POPUP
// popup widths
.esri-view-width-large .esri-popup__main-container {
  width: 550px !important;
}

.esri-view-width-xlarge .esri-popup__main-container {
  width: 650px !important;
}

.esri-popup__header-title {
  font-weight: bold;
}

.esri-feature a {
  color:$body-color !important;
}

.crosshair {
  cursor: crosshair;
}

// .esri-popup__inline-actions-container .esri-popup__action {
//   flex: 0 0 !important;
//   flex-basis: 20px !important;
//   margin-left: 0 !important;
//   margin-right: 7px !important;
// }

.pgr-popup-link-icon {
  font-size: 1rem !important;
  width: 1rem !important;
}

.esri-scale-bar.esri-widget {
  z-index: 0;
}
